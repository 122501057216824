.blue {
  background-color: blue;
}

.red {
  background-color: red;
}

.green {
  background-color: green;
}

.orange {
  background-color: orange;
}

.child {
  border: 2px solid black;
  min-height: 250px;
  overflow: hidden;
}

.child > img {
  height: 150%;
  width: 150%;
  object-fit: cover;
  transform: translate(-16.7%, -16.7%);
}

.parent {
  margin-left: 8rem;
  min-width: calc(100vw - 8rem);
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: repeat(3, minmax(0, 1fr));
  overflow: scroll;
  // height: 2000px;
}

@media screen and (max-width: 900px) {
  .parent {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
}

@media screen and (max-width: 600px) {
  .parent {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
}
