.portfolio-element-wrapper {
  height: 100%;
  width: 100%;
  border: 2px solid black;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;
  background-position: center;
}

.portfolio-element-wrapper:hover {
  transform: scale(1.05);
  transition: 0.1s linear;
  cursor: pointer;
}

.portfolio-element-wrapper > * {
  pointer-events: none;
}

.name-text-wrapper,
.technologies-icon-wrapper {
  width: 100%;
}

.name-text {
  width: 100%;
  text-align: center;
  color: white;
  background-color: rgba($color: #000000, $alpha: 0.8);
  padding: 1em;
  margin: 0;
}

.technologies-icon-wrapper {
  height: 50px;
  background-color: rgba($color: #000000, $alpha: 0.8);
  display: flex;
  justify-content: space-around;
}

.technologies-icon {
  height: 50px;
  object-fit: contain;
}

.top-origin {
  transform-origin: top;
}

.bot-origin {
  transform-origin: bottom;
}
