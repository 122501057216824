.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
}

.wrapper:hover {
  cursor: pointer;
}
