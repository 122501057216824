.navbar {
  position: fixed;
  height: 100vh;
  width: 8rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #1a1a1a;
  gap: 2rem;
}

a {
  color: #3a3a3a;
  text-decoration: none;
}

a:hover {
  color: #3a3a3a;
}
