.carousel-wrapper {
  display: flex;
  justify-content: center;
}

.hide {
  opacity: 0;
}

.pretext {
  font-size: 2rem;
  font-weight: lighter;
  transition: 1500ms ease-in;
  will-change: opacity;
}

.carousel {
  min-width: 400px;
  height: 70px;
  align-self: center;
  overflow: hidden;
  text-align: left;
  margin-left: 32px;
}

.slider {
  align-self: center;
  position: relative;
  transition: 400ms ease-out;
  will-change: left;
}

.slider > * {
  overflow: hidden;
  display: inline-block;
  position: absolute;
  font-size: 2rem;
  font-weight: bolder;
  transition: 1500ms ease-in;
  will-change: opacity;
}

.linebreak {
  display: none;
}

@media screen and (max-width: 900px) {
  .pretext {
    font-size: 1rem;
  }

  .slider > * {
    font-size: 1rem;
  }

  .linebreak {
    display: static;
  }
}
