.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  z-index: 1;
}

.hide {
  visibility: hidden;
}

.flipIcon {
  align-self: center;
}
