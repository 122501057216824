.frontpage-wrapper {
  display: flex;
  flex-direction: column;
}

.text-wrapper > h1,
.text-wrapper > h2 {
  color: #eee;
  transition: 800ms ease-in;
  will-change: opacity;
}

.helloWorldText {
  left: -200px;
  position: relative;
}

.myNameText {
  left: -150px;
  position: relative;
  margin-bottom: 6rem;
}

.hide {
  opacity: 0;
}

.text-wrapper {
  align-self: center;
  text-align: left;
  align-content: center;
  flex-direction: column;
}

h1 {
  font-size: 6rem;
  font-family: "Raleway";
}

h2 {
  font-size: 4rem;
  font-family: "RalewayBold";
}

@media screen and (max-width: 900px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2rem;
  }

  .helloWorldText {
    left: 0px;
  }

  .myNameText {
    left: 0px;
    position: relative;
    margin-bottom: 3rem;
  }
}
