.project-details-wrapper {
  display: flex;
  justify-content: center;
  margin-left: 8rem;
  min-width: calc(100vw - 8rem);
  overflow-y: auto;
  max-height: 100vh;
  box-sizing: border-box;
}

.project-details-wrapper > * {
  padding: 1rem 2rem;
}

.project-details-images {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 40%;
  min-width: 250px;
  max-width: 500px;
  margin-top: 4rem;
}

.project-details-content-wrapper {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  max-width: 600px;
  gap: 1rem;
}

.project-details-title {
}

.project-details-abstract {
  font-weight: bold;
  text-align: justify;
}

.project-details-technologies {
  display: flex;
  justify-content: space-around;
  height: 64px;
}

.project-details-technologies-icon {
  height: 64px;
}

.project-details-buttons {
}

.project-details-description {
  text-align: justify;
}
