.pdf-nav-button-wrapper {
  height: 100%;
  width: 50px;
  background-color: rgba($color: #000000, $alpha: 0.2);
  vertical-align: middle;
  display: flex;
  align-content: center;
  justify-content: center;
}

.pdf-nav-button {
  margin: auto;
  font-size: xx-large;
  padding: 0 5px;
}

.pdf-nav-button > img {
  width: 100%;
}

.pdf-nav-button:hover {
  cursor: pointer;
}

.hidden {
  visibility: hidden;
}
