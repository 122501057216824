.logo-wrapper {
  margin: 2rem;
  margin-left: 8rem;
  border-bottom: 2px solid white;
}

@media screen and (max-width: 900px) {
  .logo-wrapper {
    margin: 1rem 0;
  }
}
